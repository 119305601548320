const TeamFive = () => {
   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/team/details/vinod.jpg" alt="Vinod" />
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <h3>Vinod </h3>
                           <span>Head of Industrial IoT, SOLiD Inspire</span>
                        </div>
                        <p>Vinod brings over 27 years of experience in electronic systems and software platforms, with a deep focus on emerging technologies in Industrial IoT. His expertise spans key industries, including solar, EV batteries, mobility, semiconductor systems, power electronics, and med-tech.</p>
                        <p>As the co-founder of Megamic Electronics, Vinod has demonstrated leadership in driving innovation and delivering successful solutions in the electronics space. His career includes pivotal roles at Texas Instruments, where he contributed to critical projects as Systems & Applications Manager and Test & Characterization Manager. Prior to that, he held a prominent position at Lucent Power System, serving as Lead Power Supply Designer.</p>
                        <p>Vinod’s extensive experience in engineering and systems development has positioned him as a leader in the Industrial IoT sector, where he continues to influence advancements in technology and product innovation.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default TeamFive;