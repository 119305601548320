const TeamSix = () => {
   return (
      <>
         <section className="about__area pb-100 pt-100 bg-light p-relative">
            <div className="container">
               <div className="row flex-column-reverse flex-md-row">
                  <div className="col-xl-5 col-lg-5 pr-0 ">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <h3>Jahangir Nakra</h3>
                           <span>Head of Engineering, Solid Inspire</span>
                        </div>
                        <p>Jahangir Nakra brings a wealth of experience in engineering leadership and innovation, with a proven track record of driving technological advancements across multiple sectors. As the Founder and President of Nakra Labs Inc., and in his role as CTO at TygaBox Systems, Jahangir has led efforts in product development and engineering solutions that have contributed to significant industry progress.</p>
                        <p>Previously, as VP of Engineering at Senaya and Founder & Senior Design Engineer at Cimaron Communications, he played a key role in the creation and execution of cutting-edge technologies. His earlier roles at AT&T Bell Labs as a System Architect & Senior ASIC Designer, and at Analog Devices as an IC Design Engineer, laid a strong foundation for his extensive expertise in system design and engineering innovation.</p>
                        <p>Jahangir also shares his expertise as an Adjunct Professor at The College of New Jersey, mentoring future engineers and contributing to the academic field. He holds a master’s degree in computer engineering from UMass and a Bachelor’s in Computer Science from MS University. He has been recognized for his contributions to technology with 17 issued US patents.</p>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 offset-xl-1  offset-lg-1">
                     <div className="about__thumb m-img">
                        <img src="assets/img/team/details/jahangir-nakra.jpg" alt="Jahangir Nakra" />
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default TeamSix;