const TeamFour = () => {
   return (
      <>
         <section className="about__area pb-100 pt-100 bg-light p-relative">
            <div className="container">
               <div className="row flex-column-reverse flex-md-row">
                  <div className="col-xl-5 col-lg-5 pr-0 ">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <h3>Lee, Jung Wook</h3>
                           <span>Head of Operations, SOLiD Inspire </span>
                        </div>
                        <p>Lee Jung Wook started his career as a research project manager in 2012. Today, with his consistent efforts, skills, and persistence, he leads the SOLiD Inspire team as the “Head of Operations.”</p>
                        <p>Lee, Jung Wook handles account management, P&L planning, monitoring, strategic planning, support business strategy, and the overall business and project management.</p>
                        <p>His knowledge, realistic strategies, and keen focus on quality and efficiency has played an inevitable part in the growth of SOLiD Inspire. </p>
                        <p>Lee Jung Wook has managed research projects on budget & expenditure, management, contract management, and outcome data management in his career.</p>
                        <p>He is also experienced in leading the R&D planning team, business planning & managing team, and process innovation team.</p>
                        <p>Lee Jung Wook received his degree from Korea University (Seoul) in 2012.</p>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 offset-xl-1  offset-lg-1">
                     <div className="about__thumb m-img">
                        <img src="assets/img/team/details/lee-jung-wook.jpg" alt="Lee, Jung Wook" />
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default TeamFour;