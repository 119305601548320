const TeamTwo = () => {
   return (
      <>
         <section className="about__area pb-100 pt-100 bg-light p-relative">
            <div className="container">
               <div className="row flex-column-reverse flex-md-row">
                  <div className="col-xl-5 col-lg-5 pr-0 ">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <h3>Mrinmoy Chakraborty</h3>
                           <span>CEO, SOLiD Inspire </span>
                        </div>
                        <p><strong>Mrinmoy</strong> is the Founding CEO of SOLiD Inspire.</p>
                        <p>During his 19 years of professional journey, Mrinmoy has built and led global technology businesses in Korea, India, the US, Saudi Arabia, and the UK. </p>
                        <p>He successfully led industry-defining digital transformations in retail logistics, manufacturing, banking, commercial real estate, and transportation industries. </p>
                        <p>Prior to SOLiD, he held leadership roles at Cypress Semiconductor, Xchanging, and Onmobile. After graduating from Stanford Business School in business management as a Sloan fellow, Mrinmoy co-founded his first IoT startup in 2010 and has been a serial tech-preneur since then.</p>
                        <p>Mrinmoy also completed his MBA  from IIM Mumbai (Gold Medalist) and Bachelors in EE (Honors) from Jadavpur University, Kolkata.</p>
                        <p>Mrinmoy holds 24 international patents.</p>
                     </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 offset-xl-1  offset-lg-1">
                     <div className="about__thumb m-img">
                        <img src="assets/img/team/details/mrinmoy-chakraborty.jpg" alt="Mrinmoy Chakraborty" />
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default TeamTwo;