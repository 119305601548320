const TeamFive = () => {
   return (
      <>
         <section className="about__area pb-200 pt-100">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 pr-0 col-lg-6">
                     <div className="about__thumb m-img">
                        <img src="assets/img/team/details/jamshed-dubash.jpg" alt="Jamshed" />
                        <div className="about__shape">
                           <img src="assets/img/about/red-shape.png" alt="" />
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-5 offset-xl-1 col-lg-5 offset-lg-1">
                     <div className="about__content">
                        <div className="section__title mb-25">
                           <h3>Jamshed H. Dubash</h3>
                           <span>Chief Business Officer, Solid Inspire</span>
                        </div>
                        <p>Jamshed is a seasoned leader in digital transformation with over 30 years of experience across Industrial Manufacturing, Logistics, Retail/CPG, and Life Sciences. He specializes in leveraging advanced technologies like IoT, AI, RFID, and Machine Learning to create innovative solutions that drive operational excellence and growth.</p>
                        <p>As a visionary entrepreneur, Jamshed has founded successful startups, executed large-scale global projects, and built high-performance leadership teams. His strategic focus on innovation and customer satisfaction has earned him a stellar reputation in the industry. He continues to push the boundaries of innovation in Industry 4.0 applications—Smart Factories, Manufacturing, and Supply Chains—and Consumer IoT applications—Smart Retail, Smart Homes, and Smart Cities.</p>
                        <p>With expertise spanning Industrial and Consumer IoT, start-up leadership, P&L management, strategy development, mentoring and advisory, building leadership teams, sales and business development, program and product management, and negotiation and partnerships, Jamshed brings a comprehensive skill set to every initiative.</p>
                        <p>With a portfolio of 18 issued US patents and a commitment to advancing technology, Jamshed continues to create innovative solutions that address today’s most pressing business challenges.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default TeamFive;